import "lazysizes";
var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
window.onload = function() {
	setupSlideMenu();
	setupAreaIcons();
	const area_wrapper_list = document.querySelectorAll(".area-wrapper");
	if (area_wrapper_list){
		setupCloseButton(area_wrapper_list);
	}
	const gallery_wrapper_list = document.querySelectorAll(".gallery_wrapper");
	if (gallery_wrapper_list){
		setupCloseButton(gallery_wrapper_list);
	}
	
	setupGalleryButton();

	const map_click = document.getElementById("map-click");
	if (map_click) {
		map_click.addEventListener("click",() => {
			// shield the map on mobile for easy scrolling
			map_click.style.display = "none";
		});
	}
};


export function setupAreaIcons(){
	const area_icon_list = document.querySelectorAll(".area-icon");
	area_icon_list.forEach(area_icon => {
		area_icon.addEventListener("click",() => {
			const id = area_icon.dataset.id;
			let area_wrapper = document.querySelector(".area-wrapper[data-id='" + id + "']");
			area_wrapper.classList.add("active");
			setTimeout(function () {
				area_wrapper.classList.add("show");
			}, 10);
		});
	
	});
}

export function setupGalleryButton(){
	const gallery_btn_list = document.querySelectorAll(".gallery-btn");
	gallery_btn_list.forEach(gallery_btn => {
		gallery_btn.addEventListener("click",() => {
			const id = gallery_btn.dataset.id;
			let area_wrapper = document.querySelector(".gallery_wrapper[data-id='" + id + "']");
			area_wrapper.classList.add("active");
			setTimeout(function () {
				area_wrapper.classList.add("show");
			}, 10);
		});
	
	});
}

export function setupCloseButton(wrapper_list){
	wrapper_list.forEach(item_wrapper => {
		let closebtn = item_wrapper.querySelector(".close-btn");
		let logo = item_wrapper.querySelector(".logo");

		const closeArea = () => {
			item_wrapper.classList.remove("show");
			setTimeout(function () {
				item_wrapper.classList.remove("active");
			}, 1000);
		};
		if (closebtn){
			closebtn.addEventListener("click", closeArea);
		}
		if (logo){
			logo.addEventListener("click", closeArea);
		}
	});
}



export function setupSlideMenu(){
	const menu_buttons = [...document.querySelectorAll("#mobile-menu, .slide-menu h3")];
	menu_buttons.forEach(el => {
		el.addEventListener("click", ()=>{
			$("body").toggleClass("slide-menu-active");
		});
	});
}
//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});